@import '../../scss/style.scss';

.stats {
    padding-bottom: 44px;

    @media (max-width: 576px) {
        padding-bottom: 30px;
    }

    & .stats__list{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    & .stats__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        justify-content: center;
        // width: 270px;
        width: 22%;
        min-height: 233px;

        @media (max-width: 630px) {
            width: 50%;
            min-height: 180px;
        }
    }

    & .stats__img {
        height: 77px;
        margin-bottom: 7px;
    }

    & .stats__number {
        @include title;
        font-weight: 700;
        font-size: 26px;
        line-height: 32px;
        color: $colorMain;

        @media (max-width: 768px) {
            font-size: 22px;
        }
    }

    & .stats__text {
        @include textSans;
        // max-width: 72%;
        // line-height: 14px;
    }
}