@import '../../../scss/style.scss';

.modal-popular {
    & .container {
        padding: 0 250px;

        @media (max-width: 1400px) {
            padding: 0 200px;
        }

        @media (max-width: 1300px) {
            padding: 0 150px;
        }

        @media (max-width: 1048px) {
            padding: 0 80px;
        }

        @media (max-width: 992px) {
            padding: 0 40px;
        }

        @media (max-width: 576px) {
            padding: 0 15px;
        }
    }

    & .modal-container {
        @media (max-width: 576px) {
            border-radius: 30px;
        }
    }

    & .modal__content {
        padding: 40px 20px 30px;

        @media (max-width: 992px) {
            padding: 40px 15px 15px;
        }

        @media (max-width: 360px) {
            padding: 40px 10px 10px;
        }
    }

    & .btn-close-modal {
        right: 10px;
        top: 10px;
        width: 30px;
        height: 30px;

        & svg {
            width: 100%;
            height: 100%;
        }
    }

    & .modal-popular__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @media (max-width: 576px) {
            gap: 10px;
        }
    }

    & .txt-wrapp {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    & .modal-popular__content-txt {
        @include textSans;
        font-weight: 700;
        font-size: 18px;
        line-height: 120%;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 16px;
        }

        @media (max-width: 576px) {
            font-size: 14px;
        }
    }

    & .modal-popular__content-txt.red {
        font-size: 20px;
        color: $colorRed;

        @media (max-width: 768px) {
            font-size: 18px;
        }

        @media (max-width: 576px) {
            font-size: 16px;
        }
    }

    & .video {
        width: 100%;
        // height: 400px;
        height: 40vh;
        border-radius: 15px;
        overflow: hidden;


        // @media (max-width: 1024px) {
        //     height: 300px;
        // }
    }

    & .btn-wrapp {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        gap: 20px;

        @media (max-width: 576px) {
            gap: 10px;
        }
    }

    & .btn-link {
        @include btn-reset;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 25px;
        width: 270px;
        height: 36px;

        @media (max-width: 820px) {
            font-size: 14px;
            width: 240px;
        }

        @media (max-width: 576px) {
            font-size: 12px;
            width: 200px;
            height: 30px;
        }
    }

    & .white-btn {
        border: 1px solid $colorMain;
        background-color: $colorWhite;
        color: $colorMain;
        text-align: center;
        @include tab-grey-linear;
    }

    & .grey-btn {
        background-color: $colorMain;
        color: $colorWhite;
        box-shadow: 0px 4px 10px rgba(12, 7, 50, 0.25);
        text-align: center;
        @include grey-linear;
    }
}