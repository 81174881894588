@import '../../scss/style.scss';

.login-page__input-wrapper .cart-textarea {
    @include textUnbounded-two;
    padding: 10px;
    outline: none;
    border: none;
    resize: none;
    min-height: 159px;
    border-radius: 8px;
    background-color: $colorFooterBackground;
    color: $colorMain;

    &:focus {
        outline: 1px solid $colorMain;
    }
}

.no-product-header {

    .no-product-btn {
        @include btn-reset;
        @include red-linear;
        @include textUnbounded-two;
        line-height: 1;
        padding: 6px 14px 5px;
        background-color: $colorRed;
        // width: 120px;
        border-radius: 20px;
        color: $colorWhite;

        @media(max-width: 1200px) {
            font-size: 12px;
        }

        @media(max-width: 1024px) {
            font-size: 14px;
            padding: 8px 14px;
        }

        @media (max-width: 360px) {
            font-size: 12px;
            line-height: 14px;
            width: 100%;
        }

    }

    .wrapp-confirm-cancel-btn {
        .no-product-btn {
            margin-right: 20px;
            margin-top: 0;
            padding: 12px 30px;
            width: 185px;

            @media(max-width: 1200px) {
                font-size: 14px;
            }

            @media (max-width: 576px) {
                margin-right: 0;
            }

            @media (max-width: 470px) {
                font-size: 12px;
                line-height: 14px;
            }

        }

        @media (max-width: 576px) {
            align-items: center;
        }

        .accepted__order-all-sum-txt {
            @include btn-reset;
            color: $colorMain;
            transition: color .4s ease-in-out;

            &:focus-visible,
            &:hover,
            &:active {
                color: $colorBlack;
            }

            @media (max-width: 470px) {
                margin: 0;
                font-size: 12px;
                line-height: 14px;
            }
        }
    }

    .modal__content {
        padding: 50px 40px;

        @media (max-width: 576px) {
            padding: 20px 10px;
            max-height: 70vh;
        }
    }

    .no-product-form {
        span {
            display: inline-block;
            @include title;
            margin-bottom: 20px;
            text-align: center;

            @media (max-width: 576px) {
                font-size: 17px;
            }
        }

        .login-page__input-wrapper {
            position: relative;
            margin-bottom: 20px;
            width: 100%;

            @media (max-width: 576px) {
                margin-bottom: 12px;
            }

            .login-page__input {

                &:focus {
                    outline: 1px solid $colorMain;
                }

                @media (max-width: 470px) {
                    height: 30px;
                }
            }
        }

        .error-message {
            top: 1.65em;

            @media (max-width: 576px) {
                font-size: 10px;
            }
        }

        .cart-textarea.login-page__input {
            padding: 10px;
            min-height: 56px !important;
        }

        .error-login {
            outline: 1px solid $colorRed;
        }
    }

    .wrapp-confirm-cancel-btn {
        padding-top: 20px;
    }

    .login-label {
        @media (max-width: 576px) {
            margin-bottom: 10px;
            font-size: 10px;
            line-height: 14px;
        }

    }
}

.modal-message-wrapp {
    .modal-container {
        margin: 0 auto;
        width: 60%;

        @media (max-width: 1024px) {
            width: 80%;
        }

        @media (max-width: 768px) {
            width: 100%;
        }
    }

    .modal-message {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px 0;

        span {
            @include title;
            line-height: 120%;
            text-align: center;
            // margin: 50px 0;
            color: $colorGreen;

            @media (max-width: 768px) {
                font-size: 18px;
            }

            @media (max-width: 576px) {
                font-size: 16px;
            }
        }

        .color-red {
            color: $colorRed;
        }
    }
}

@media (max-width: 576px) {
    .wrapp-confirm-cancel-btn {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    .no-product-header .no-product-btn {
        font-size: 12px;
    }

    .login-page__input-wrapper .cart-textarea {
        min-height: 110px;
    }
}

@media (max-width: 470px) {
    .login-page__input-wrapper .cart-textarea {
        font-size: 12px;
    }
}