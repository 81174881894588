@import '../../scss/style.scss';

.product-list {
    padding-top: 45px;
    padding-bottom: 45px;

    @media (max-width: 576px) {
        padding: 30px 0;
    }

    & .product-list__title {
        @include title;
        margin-bottom: 60px;
        font-size: 26px;
        line-height: 25px;
    }

    & .product-list__wrapp {
        display: flex;
        flex-wrap: wrap;
        gap: 20px 2%;
        width: 100%;
    }

    & .product-list__item {
        position: relative;
        display: flex;
        flex-direction: column;
        width: 23.5%;
        // width: 270px;
        // min-height: 462px;
        background-color: $colorWhite;
        box-shadow: 0px 4px 15px rgba(12, 7, 50, 0.25);
        border-radius: 40px;

        @media(max-width: 992px) {
            width: 32%;
        }

        @media(max-width: 768px) {
            width: 49%;
        }

        @media(max-width: 504px) {
            width: 100%;
        }

    }

    & .product-list__top {
        padding: 15px;
        text-align: center;
        height: 220px;
    }

    & .product-list__img {
        object-fit: contain;
        width: 100%;
        height: 100%;
    }

    & .product-list__bottom {
        display: flex;
        flex: 1;
        flex-direction: column;
        padding: 25px;
        height: 50%;
    }

    & .product-list__subtitle {
        @include title;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 20px;
    }

    & .product-list__text {
        @include textSans;
        margin-bottom: 10px;
        line-height: 14px;
        color: $colorLightGray;

        & .product-list__textblack {
            font-weight: 700;
            color: $colorBlack;
        }
    }

    // & .product-list__text:last-of-type {
    //     margin-bottom: 0;
    // }

    & .product-list__btn-wpapp {
        display: flex;
        margin-top: auto;
        // position: absolute;
        // bottom: 25px;
        // left: 0;
        width: 100%;
        // padding: 0 25px;
    }

    & .product-list__btn {
        @include btn-reset;
        // @include title;
        @include textUnbounded-two;
        padding: 8px;
        padding-bottom: 0;
        width: 100%;
        text-align: center;
        color: $colorRed;
        transition: color .2s ease-in-out;
        // border: 1px solid $colorMain;
        // border-radius: 25px;
        // font-weight: 500;
        // font-size: 16px;
        // line-height: 20px;
        // color: $colorMain;
        // @include tab-grey-linear;

        &:focus-visible,
        &:hover,
        &:active {
            color: $colorBlack;
        }
    }
}


@media (max-width: 768px) {
    // .product-list .product-list__item {
    //     width: 226px;
    // }

    .product-list .product-list__subtitle,
    .product-list .product-list__btn {
        font-size: 14px;
    }
}

@media (max-width: 576px) {
    // .product-list .product-list__item {
    //     width: 263px;
    // }

    .product-list .product-list__wrapp {
        justify-content: center;
    }

    .product-list .product-list__title {
        margin-bottom: 20px;
        font-size: 22px;
    }
}

@media (max-width: 360px) {
    .product-list .product-list__title {
        font-size: 20px;
    }
}