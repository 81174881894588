@import '../../scss/style.scss';

.header-customer {
  // position: fixed;
  position: sticky;
  top: 0;
  // left: 0;
  width: 100%;
  background-color: $colorWhite;
  box-shadow: 0px 4px 25px rgba(12, 7, 50, 0.25);
  z-index: 100;

  & .header__container {
    display: flex;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  & .header__logo-customer {
    display: flex;
    margin-right: 4%;
    align-items: center;
    outline: 0;
    padding: 10px 5px;
    transition: box-shadow .2s ease-in-out;

    & path {
      transition: fill .2s ease-in-out;
    }

    & .color-black {
      transition: fill .2s ease-in-out;
    }

    // &:focus-visible,
    // &:hover,
    // &:active {
    //     // transform: scale(1.1);
    //     border-radius: 25px;
    //     box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.7);
    // }

    &:focus-visible path,
    &:hover path,
    &:active path {
      fill: $colorRed;
    }

    &:focus-visible .color-black,
    &:hover .color-black,
    &:active .color-black {
      fill: $colorBlack;
    }
  }

  & .header__nav-customer {
    display: flex;
    width: 100%;
  }

  & .header__list-customer {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: $colorMain;
    gap: 30px;

    @media(max-width: 1356px) {
      gap: 20px;
      // font-size: 12px;
    }

    @media(max-width: 1200px) {
      font-size: 12px;
    }

    @media(max-width: 1024px) {
      font-size: 14px;
    }
  }

  .header__list-one {
    margin-right: auto;
  }

  & .header__link {
    display: flex;
    // margin-right: 40px;
    align-items: center;
    outline: 0;
    transition: color .2s ease-in-out,
      text-shadow .2s ease-in-out;

    &:focus-visible,
    &:hover,
    &:active {
      color: $colorRed;
      text-shadow: 0px 0px 2px #31465A;
    }

    &:focus-visible .catalog-svg .svg-color-active,
    &:hover .catalog-svg .svg-color-active,
    &:active .catalog-svg .svg-color-active {
      fill: $colorRed;
    }

    &:focus-visible .shopping-cart-svg path,
    &:hover .shopping-cart-svg path,
    &:active .shopping-cart-svg path {
      fill: $colorRed;
    }
  }

  & .header__link .catalog-svg .svg-color-active {
    transition: fill .2s ease-in-out;
  }

  & .header__link .shopping-cart-svg path {
    transition: fill .2s ease-in-out;
  }




  & .header__link-last,
  & .header__link-dropdown {
    margin-right: 0;
    @include btn-reset;
    font-size: 14px;

    @media(max-width: 1200px) {
      font-size: 12px;
    }
  }

  & .catalog-svg {
    // display: flex;
    // align-self: center;
    margin-right: 5px;
  }

  & .arrow-svg {
    padding-left: 5px;
  }

  & .header__form {
    display: flex;
    align-items: center;
    margin-right: 22px;
  }

  & .header__form-search {
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;
  }

  & .header__btn {
    @include btn-reset;
    padding: 10px 44px;
    border: 1px solid $colorMain;
    border-radius: 25px;
    background-color: $colorWhite;
    color: $colorMain;
  }

  .header__item-customer-last {
    position: relative;
  }

  .header__dropdown-wrapp {
    padding-top: 30px;
    position: absolute;
    top: 12px;
    right: 0;
  }

  .header__dropdown-wrapp-help {
    padding-top: 30px;
    position: absolute;
    top: 9px;
    right: -100%;
  }

  .header__dropdown {
    display: flex;
    flex-direction: column;
    padding: 20px;
    min-width: 200px;
    background-color: $colorWhite;
    border-radius: 0 0 20px 20px;
    box-shadow: 0px 4px 25px rgba(12, 7, 50, 0.25);
    gap: 10px;
    // opacity: 0;
    // visibility: hidden;
    // transition: opacity 0.3s ease-in-out;
  }

  // .header__item-customer-last:hover .header__dropdown {
  //     opacity: 1;
  //     visibility: visible;
  //   }

  .header__dropdown li {
    width: 100%;
  }

  .header__dropdown li:last-child {
    padding-top: 12px;
  }

  .header__dropdown li .sidebar__svg {
    margin-right: 4px;
  }

  // & .header__link-txt {
  //     margin-right: 4px;
  // }
}

.active {
  color: $colorRed;
}

.active .catalog-svg .svg-color-active {
  fill: $colorRed;
}

.active .arrow-svg svg path {
  stroke: $colorRed;
  fill: $colorWhite;
}

.active svg path {
  stroke: $colorRed;
}

.active svg path {
  fill: $colorRed;
}

.sidebar__title-mobail-customer {
  display: none;
}

.menu-trigger-customer {
  display: none;
  cursor: pointer;
}

.sidebar-list-customer {
  display: none;
}

.header__link.header__link-mobail-customer {
  display: none;
}

.logo-mobile {
  display: none;
}

@media (max-width: 1024px) {

  .header__item-customer.display-none {
    display: none;
  }

  .header-customer .header__container {
    justify-content: space-between;
    align-items: center;
    height: 60px;
  }

  .header-customer .header__logo-customer {
    justify-content: center;
    margin-right: 0;
  }

  .header-customer .header__link-last,
  .header__item-customer-last {
    display: none;
  }

  .header__nav-customer {
    display: none;
    flex-direction: column;
  }

  .header-customer .header__nav-customer {
    height: calc(100vh - 60px);
    overflow: auto;
  }

  .header__link.header__link-mobail-customer {
    display: flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    color: $colorMain;
  }

  .header__list-customer {
    flex-direction: column;
  }

  .header-customer .header__list-customer {
    gap: 0;
  }

  .header-customer .header__list-one {
    margin-right: 0;
    gap: 0;
  }

  // .header__nav-burger {
  //     display: block;
  // }

  .header__item-customer {
    margin-bottom: 30px;
  }

  .header__item-customer.header__item-customer-no-product {
    margin-bottom: 60px;
  }

  .header__nav-customer {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 56px;
    background-color: #fff;
    box-shadow: inset 0px 4px 25px rgba(12, 7, 50, 0.25);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
  }

  .header__list-visible-customer {
    opacity: 1;
    visibility: visible;
  }

  .header-customer .header__link {
    margin-right: 0;
  }

  .sidebar-list-customer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .sidebar__btn {
    @include btn-reset;
    @include textUnbounded-one;
    padding: 8px 40px;
    text-align: center;
    border-radius: 25px;
    background-color: $colorRed;
    font-size: 14px;
    color: $colorWhite;
    @include red-linear;
  }

  .sidebar__item,
  .sidebar__link {
    @include btn-reset;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .sidebar__item:not(:last-child) {
    margin-bottom: 30px;
  }

  .sidebar__item.sidebar__item-create {
    margin-bottom: 60px;
  }

  .sidebar__title {
    @include title;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: $colorMain;
  }

  .header-customer .header__link .sidebar__title-mobail-customer {
    transition: color .2s ease-in-out;
  }

  .header-customer .header__link:focus-visible .sidebar__title-mobail-customer,
  .header-customer .header__link:hover .sidebar__title-mobail-customer,
  .header-customer .header__link:active .sidebar__title-mobail-customer {
    color: $colorRed;
  }

  .sidebar__title-mobail-customer {
    display: inline-block;
    margin-left: 6px;
  }

  .sidebar__svg {
    margin-right: 6px;
  }

  .menu-trigger-customer {
    position: relative;
    display: inline-block;
    transition: all 0.3s;
    width: 45px;
    height: 30px;

    span {
      display: inline-block;
      transition: all 0.3s;
      position: absolute;
      left: 0;
      width: 100%;
      height: 3px;
      background-color: $colorMain;
      border-radius: 4px;

      &:nth-of-type(1) {
        // top: 20%;
        top: 0%;
      }

      &:nth-of-type(2) {
        // top: 55%;
        top: 50%;
      }

      &:nth-of-type(3) {
        // bottom: 0;
        top: 100%;
      }
    }
  }

  .menu-trigger-customer span:nth-of-type(3),
  .menu-trigger-customer.active-burger span:nth-of-type(3) {
    transition: none;
  }

  .menu-trigger-customer.active-burger {
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg);
    height: 40px;
  }

  .menu-trigger-customer.active-burger span:nth-of-type(1) {
    -webkit-transform: translateY(20px) rotate(-50deg);
    transform: translateY(20px) rotate(-50deg);
  }

  .menu-trigger-customer.active-burger span:nth-of-type(2) {
    -webkit-transform: translateY(0) rotate(50deg);
    transform: translateY(0) rotate(50deg);
  }

  .menu-trigger-customer.active-burger span:nth-of-type(3) {
    opacity: 0;
  }

}

@media (max-width: 576px) {
  .shopping-cart-svg {
    width: 24px;
    height: 24px;
  }

  .logo-mobile {
    display: block;
  }

  .logo-desctop {
    display: none;
  }

  .menu-trigger-customer {
    width: 35px;
    height: 20px;
  }

  .header-customer .header__logo-customer {
    padding: 4px;
  }

  .sidebar__btn {
    font-size: 12px;
  }

  .header__nav-customer {
    padding: 46px 16px;
  }

  .sidebar__item:not(:last-child) {
    margin-bottom: 16px;
  }

  .header__item-customer {
    margin-bottom: 16px;
    font-size: 12px;
  }

  .header__item-customer.header__item-customer-no-product {
    margin-bottom: 50px;
  }

  .sidebar__item.sidebar__item-create {
    margin-bottom: 50px;
  }

  .header-customer .header__list-customer {
    gap: 0;
  }

  .sidebar__title,
  .header-customer .header__list-customer {
    font-size: 12px;
  }
}
