@import '../../scss/style.scss';


.stages {
    padding-bottom: 45px;

    @media (max-width: 576px) {
        padding-bottom: 30px;
    }

& .stages__container {
    background-color: $colorWhite;
    box-shadow: 0px 4px 25px rgba(12, 7, 50, 0.25);
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
}

& .wrapp-btn-white {
    display: inline-flex;
    width: 50%;
    height: 101px;
    background-color: $colorWhite;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
}

& .stages__btn {
    @include btn-reset;
    @include title;
    text-align: center;
    line-height: 21px;
    font-size: 22px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background-color: $colorFooterBackground;
    width: 100%;
    height: 81px;
    transition: background-color .2s ease-in-out, 
    color .2s ease-in-out,
    box-shadow .2s ease-in-out,
    border-top-left-radius .2s ease-in-out,
    border-top-right-radius .2s ease-in-out;
}

& .stages__list-wrapp {
    padding: 40px 100px 60px;
}

& .stages__list {
    margin: 0;
}

& .tabs-stages--active {
    background-color: $colorWhite;
    box-shadow: -5px -17px 25px -11px rgba(12, 7, 50, 0.25);
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    color: $colorMain;
    cursor: auto;
}

& .stages__item {
        display: none;
    }
    
& .tabs-item--active {
        display: block;
    }
    
    
& .stages__video {
        width: 100%;
        // height: 630px;
        height: 100%;
        border: none;
    }

& .stages__list-item {
    font-family: $font-roboto;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: $colorBlack;
}

& .stages__list-item:not(:last-child) {
    margin-bottom: 15px;
}
}

@media(max-width: 1024px) {
    .stages .stages__list-wrapp {
        padding: 30px 62px 40px;
        padding-left: 20px;
    }

    .stages .stages__list-item {
        font-size: 16px;
        line-height: 18px;
    }

    .stages .stages__btn {
        font-size: 18px;
        height: 65px;
    }

    .stages .wrapp-btn-white {
        height: 75px;
    }
}

@media(max-width: 768px) {
    // .stages .stages__video {
    //     height: 340px;
    // }

    .stages .stages__btn {
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    .stages .stages__container {
        border-bottom-right-radius: 25px;
        border-bottom-left-radius: 25px;
    }
}

@media(max-width: 576px) {
    .stages .stages__list-wrapp {
        padding: 28px;
        padding-left: 10px;
    }

    .stages .wrapp-btn-white {
        height: 58px;
    }

    .stages .stages__btn {
        height: 48px;
        font-size: 16px;
    }

    .stages .stages__list-item {
        font-size: 14px;
        line-height: 16px;
    }

    // .stages .stages__video {
    //     height: 220px;
    // }
}

@media(max-width: 410px) {
    .stages__wrapp-btn {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 28px;
        padding-top: 10px;
        width: 100%;
        box-shadow: 0px 4px 25px rgba(12, 7, 50, 0.25);
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
    }

    .stages .wrapp-btn-white {
        width: 100%;
        height: 47px;
    }

    .stages .stages__btn {
        border-radius: 16px;
        width: 100%;
    }

    .stages .tabs-stages--active {
        box-shadow: none;
    }
}
