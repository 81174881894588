@import '../../scss/style.scss';

.rating-img {
    position: relative;
}

.rating-img img {
    position: absolute;
    bottom: -60%;
    // left: 21%;
    width: 100%;
    z-index: -1;
}

.rating {
    padding-top: 45px;
    padding-bottom: 45px;

    @media (max-width: 576px) {
        padding: 30px 0;
    }
    // background-image: url('../../img/backgr.png');
    // background-repeat: no-repeat;
    // background-position: center;
    // background-size: cover;

    & .rating__list {
        display: flex;
        flex-wrap: wrap;
        gap: 28px 2%;
    }

    & .rating__item {
        display: flex;
        align-items: center;
        // width: calc((100% - (40px * 2)) / 3);
        width: 23.5%;
        @media(max-width: 1024px) {
            width: 49%;
        }

        @media(max-width: 576px) {
            width: 100%;
        }
    }

    & .rating__icon {
        margin-right: 18px;
    }

    & .rating__text {
        @include title;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
    }
}

.registration {
    padding-top: 45px;
    padding-bottom: 45px;

    @media (max-width: 576px) {
        padding: 30px 0;
    }

    & .registration__container {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: center;
        padding: 40px 90px;
        background-color: $colorFooterBackground;
        border-radius: 50px;
    }

    & .registration__title {
       @include title;
       width: 54%;
       line-height: 27px;

       & .registration__color {
        color: $colorMain;
       }
    }

    // & .registration__button {
    //     @include btn-reset;
    //     padding: 8px 27px;
    //     background-color: $colorMain;
    //     border-radius: 25px;
    //     color: $colorWhite;
    //     box-shadow: 0px 4px 10px rgba(12, 7, 50, 0.25);
    // }
}


@media(max-width: 992px) {

    .registration {
        & .registration__container {
            flex-direction: column;
            padding: 40px;
            border-radius: 40px;
        }

        & .registration__title {
            margin-bottom: 32px;
            text-align: center;
        }
    }
}

@media(max-width: 768px) {
    .registration .registration__title {
        width: 65%;
    }

    .registration .registration__container {
        border-radius: 16px;
    }
}

@media(max-width: 576px) {
    .registration .registration__title {
        width: 100%;
        font-size: 16px;
    }

    .registration .registration__container {
       padding: 40px 12px;
    }

    .rating-img img {
        bottom: -10%;
    }
}