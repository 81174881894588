@import '../../scss/style.scss';

.no-product-header .no-product-form .input {
  &__wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    padding-top: 20px;

    .error-message {
      top: 0;
    }
  }

  &__file {
    position: absolute;
    width: 1px;
    height: 1px;
    opacity: 0;
  }

  &__file-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
  }

  &__file-button {
    @include btn-reset;
    display: flex;
    align-items: center;
    height: auto;

    .input__file-button-text {
      margin-bottom: 0;
      // margin-top: 2px;
      font-size: 18px;
      font-weight: 500;
      line-height: 100%;
      color: $colorMain;
      text-align: right;
    }

    & svg path,
    & .add-file-txt {
      transition: all .2s ease-in-out;
    }

    &:hover svg path,
    &:focus-visible svg path,
    &:active svg path {
      fill: $colorBlack;
    }

    &:hover .input__file-button-text,
    &:focus-visible .input__file-button-text,
    &:active .input__file-button-text {
      color: $colorBlack;
    }

    svg {
      width: 46px;
      height: 46px;
    }

  }

}


// .input__file-button-text {
//   margin-bottom: 0;
//   margin-top: 4px;
//   font-size: 18px;
//   font-weight: 500;
//   line-height: 100%;
//   color: $colorMain;
// }

.input__file-button-delete {
  margin-left: 1em;
  @include btn-reset;
  font-size: 30px;
  color: #B10207;
  background-color: transparent;
}

@media (max-width: 820px) {
  .no-product-header .no-product-form .input__file-button svg {
    width: 40px;
    height: 40px;
  }

  .no-product-header .no-product-form .input__file-button .input__file-button-text {
    font-size: 14px;
  }

  .no-product-header .no-product-form .input__file-button,
  .input__file-button-delete {
    font-size: 20px;
  }
}

@media (max-width: 576px) {
  .no-product-header .no-product-form .input__file-button .input__file-button-text {
    font-size: 12px;
  }
}
