@import '../../scss/style.scss';

.registration__btn {
    @include btn-reset;
    display: inline-block;
    padding: 11px 40px;
    background-color: $colorMain;
    border-radius: 25px;
    font-size: 18px;
    line-height: 22px;
    color: $colorWhite;
    box-shadow: 0px 4px 10px rgba(12, 7, 50, 0.25);
    height: 44px;
    text-align: center;
    @include grey-linear;
}

@media (max-width: 768px) {
    .registration__btn {
        font-size: 14px;
        padding: 8px 40px;
        height: 36px;
    }

    .dot {
        margin: 0 14px;
    }
}