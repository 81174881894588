@import '../../scss/style.scss';

.advantage {
    padding-top: 45px;
    padding-bottom: 45px;

    @media (max-width: 576px) {
        padding: 20px 0 30px;
    }

    & .advantage__wrapp {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex-wrap: wrap;
        align-items: center;
        text-align: center;
        padding: 60px 90px;
        background-color: $colorFooterBackground;
        border-radius: 40px;
        gap: 20px;

        @media (max-width: 768px) {
            padding: 50px;
        }

        @media (max-width: 576px) {
            padding: 30px;
        }

        @media (max-width: 360px) {
            padding: 20px;
            border-radius: 30px;
        }
    }

    & .advantage__txt {
        @include textSansTd;
        font-weight: 700;
        font-size: 24px;

        @media (max-width: 576px) {
            font-size: 20px;
        }

        @media (max-width: 360px) {
            font-size: 14px;
            line-height: 100%;
        }
    }

    & .advantage_btn {
        @include btn-reset;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 5px;
        border-radius: 25px;
        width: 374px;
        height: 36px;
        background-color: $colorMain;
        color: $colorWhite;
        box-shadow: 0px 4px 10px rgba(12, 7, 50, 0.25);
        text-align: center;
        @include grey-linear;

        @media (max-width: 820px) {
            font-size: 14px;
        }

        @media (max-width: 576px) {
            font-size: 12px;
            width: 100%;
            height: 30px;
        }

        @media (max-width: 360px) {
            font-size: 10px;
        }
    }
}