@import '../../scss/style.scss';

.main-video {
    padding: 40px 0;

    @media (max-width: 576px) {
        padding-bottom: 20px;
    }
}

.main-video__wrapp {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 40px;

    @media (max-width: 576px) {
        gap: 20px;
    }
}

.main-video__title {
    @include title;
    font-size: 35px;
    color: $colorRed;

    @media (max-width: 576px) {
        font-size: 28px;
    }

    @media (max-width: 360px) {
        font-size: 24px;
    }
}

.main-video__video {
    width: 100%;
    // height: 600px;
    height: auto;
    border-radius: 20px;
    overflow: hidden;
    object-fit: cover;
    box-shadow: 8px 11px 23.4px 0 rgba(12, 7, 50, 0.25), -2px 2px 4px 0 rgba(29, 15, 19, 0.15), 24px 37px 41.6px 0 rgba(0, 0, 0, 0.1);
    @media (max-width: 576px) {
        height: 300px;
    }
}