@import '../../scss/style.scss';

.setting__top-wrapp {
    display: flex;
    margin-bottom: 40px;
}

.setting__title {
    @include privateTitle;

}

.saved-message {
    @include textSans;
    display: flex;
    align-items: center;
    align-self: center;
    padding-left: 40px;
    color: $colorGreen;
}

.saved-message svg {
    margin-right: 5px;
}

.setting__info-wrapp {
    padding: 40px;
    margin-bottom: 20px;
    background-color: $colorWhite;
    border-radius: 40px;
    box-shadow: 0px 4px 15px 0px rgba(12, 7, 50, 0.25);
}

// .setting__info-wrapp:last-of-type {
//     margin-bottom: 40px;
// }

.login-page__form {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    width: 100%;
}

.input-password__wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.login-page__input-wrapper {
    display: flex;
    flex-direction: column;
    width: 47%;
}

.login-page__form-first {
    margin-bottom: 20px;
}

.input-password__wrapper {
    position: relative;
    margin-bottom: 15px;
}

.login-btn {
    @include btn-reset;
    align-self: flex-start;
    border-bottom: 1px solid $colorRed;
    padding-top: 4px;
    margin-top: -14px;
    margin-bottom: 15px;
    font-weight: 400;
    font-size: 12px;
    color: $colorRed;
}

.input-password__field.error-pass {
    outline: 1px solid $colorRed;
}

.password-wrapp {
    position: relative;
    display: flex;
    flex-direction: column;
}

.error-message {
    @include textUnbounded-one;
    position: absolute;
    top: 26%;
    font-size: 14px;
    line-height: 0;
    color: $colorRed;
    z-index: 1;

    @media (max-width: 576px) {
        font-size: 12px;
    }
}

.password-btn-save, .password-btn-cancel {
    @include btn-reset;
    padding-top: 4px;
    font-weight: 400;
    font-size: 12px;
}

.password-btn-save {
    margin-right: 30px;
    border-bottom: 1px solid $colorGreen;
    color: $colorGreen;
}

.password-btn-cancel {
    border-bottom: 1px solid $colorMain;
    color: $colorMain;
}

.password-text {
    @include textSans;
    padding-top: 4px;
    padding-bottom: 6px;
    font-size: 12px;
    line-height: 12px;
    color: $colorMain;
}

.change-password-btn {
    @include btn-reset;
    position: relative;
    align-self: flex-end;
    background-color: $colorRed;
    border-radius: 25px;
    height: 40px;
    width: 47%;
    font-size: 14px;
    color: $colorWhite;
    @include red-linear;
}

.input-password__btn {
    @include btn-reset;
    position: absolute;
    right: 12px;
    top: 18%;
}

.login-label {
    @include textUnbounded-two;
    margin-bottom: 14px;
    color: $colorMain;
}

.input-password__field, .login-page__input {
    @include textUnbounded-two;
    height: 40px;
    padding-left: 10px;
    padding-right: 10px;
    border: none;
    outline: none;
    border-radius: 8px;
    background-color: $colorFooterBackground;
    color: $colorMain;
    transition: outline .2s ease-in-out;

    &:focus {
        outline: 1px solid $colorMain;
    }
}

.input-password__field {
    padding-right: 42px;
}

.login-page__btn {
    @include btn-reset;
    @include textUnbounded-two;
    height: 40px;
    margin-top: 40px;
    margin-bottom: 15px;
    background-color: $colorRed;
    border-radius: 25px;
    color: $colorWhite;
}

.btn-save.change-password-btn {
    float: right;
    padding: 11px 40px;
    margin-top: 24px;
    width: auto;
    background-color: $colorGreen;
    @include green-linear;
}

.login-page__form-two .login-page__input-wrapper {
    width: 30%;
}

.login-page__form-three .login-page__input-wrapper {
    width: 100%;
}

@media(max-width: 860px) {
    .login-page__form-two {
        flex-wrap: wrap;
        gap: 20px;
    }
    .login-page__form-two .login-page__input-wrapper {
        width: 47%;
    }
}

@media(max-width: 640px) {
    .login-page__form {
        flex-direction: column;
        gap: 20px;
    }

    .login-page__input-wrapper {
        width: 100%;
    }

    .change-password-btn {
        align-self: flex-start;
        width: 50%;
    }

    .login-page__form-two .login-page__input-wrapper {
        width: 100%;
    }
}

@media(max-width: 470px) {
    .change-password-btn {
        width: 100%;
        font-size: 12px;
    }

    .setting__info-wrapp {
        padding: 40px 18px;
    }

    .btn-save.offers__btn {
        width: 100%;
    }

    .input-password__field, .login-page__input {
        font-size: 12px;
    }

    .setting__top-wrapp {
        flex-direction: column;
    }

    .setting__title {
        text-align: center;
        margin-bottom: 20px;
    }

    .saved-message {
        padding: 0;
    }
}