@import '../../scss/style.scss';

.modal {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-color: rgba(49, 70, 90, 0.60);
    z-index: 100;
    opacity: 0;
    pointer-events: none;
    transition: opacity .2s ease-in-out;

    &.modal-active {
        opacity: 1;
        pointer-events: all;
    }

    & .modal-container {
        background-color: $colorWhite;
        padding: 10px 20px;
        border-radius: 40px;
        overflow: hidden;
    }

    &__content {
        // min-width: 400px;
        position: relative;
        // padding: 100px 10px;
        padding: 30px 120px;
        max-height: 80vh;
        background-color: $colorWhite;
        overflow-y: auto;
        

        &::-webkit-scrollbar {
            // scroll-snap-align: start;
            // margin-left: 20px;
            width: 7px;
            background-color: #f5f5f5;
          }
        
          &::-webkit-scrollbar-thumb {
            background-color: #888;
            border-radius: 5px;
            height: 50px;
          }

          &:hover::-webkit-scrollbar-thumb {
            background-color: #31465A;
          }

        //   &::-webkit-scrollbar-track {
        //     // height: 40px;
        //     background-color: red;
           
        //   }
        
        //   &::-moz-scrollbar {
        //     width: 10px;
        //     background-color: #f5f5f5;
        //   }
        
        //   &::-moz-scrollbar-thumb {
        //     background-color: #888;
        //     border-radius: 5px;
        //   }
        
        //   &::-ms-scrollbar {
        //     width: 10px;
        //     background-color: #f5f5f5;
        //   }
        
        //   &::-ms-scrollbar-thumb {
        //     background-color: #888;
        //     border-radius: 5px;
        //   }
        // &::-webkit-scrollbar {
        //     display: none;
        //   }
        
        //   /* Create a custom scrollbar */
        //   &::after {
        //     content: '';
        //     position: absolute;
        //     top: 0;
        //     right: 0;
        //     width: 8px;
        //     height: 40%;
        //     background-color: #f5f5f5;
        //     border-radius: 5px;
        //     transform: translateX(-21px); /* Move the scrollbar to the left */
        //     z-index: 1;
        //   }
        
        //   /* Style the thumb of the custom scrollbar */
        //   &:hover::after {
        //     background-color: #888;
        //   }

        //   &::-webkit-scrollbar::after {
        //     opacity: 1;
        //   }
    }

    &__top {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}

.btn-close-modal {
    @include btn-reset;
    position: absolute;
    right: 35px;
    top: 35px;
    transition: transform .3s ease-in-out;

    &:focus-visible,
    &:hover,
    &:active {
        transform: scale(1.1);
    }

}

.btn-close-modal svg path {
    transition: stroke .3s ease-in-out;
}

.btn-close-modal:focus-visible svg path,
.btn-close-modal:hover svg path,
.btn-close-modal:active svg path {
    stroke: $colorRed;
}

.modal .address-list {
    padding-top: 0;
}

// .modal__content::-webkit-scrollbar {
//     // position: absolute;
//     // right: 5px;
//     width: 8px;
//     padding: 40px;
//   }

//   .modal__content::-webkit-scrollbar-track {
//     background: #f1f1f1;
//   }

//   .modal__content::-webkit-scrollbar-thumb {
//     background: #888;
//   }

//   .modal__content::-webkit-scrollbar-thumb:hover {
//     background: #555;
//   }

@media (max-width: 992px) {
    .modal__content {
        padding: 30px 54px;
    }

    .btn-close-modal {
        right: 20px;
        top: 20px;
    }
    
}

@media (max-width: 576px) {
    .modal__content {
        max-height: 82vh;
    }

    .modal__content {
        padding: 30px 8px;
        padding-top: 60px;
    }

    .modal .modal-container {
        padding: 10px 10px;
    }

    .btn-close-modal {
        right: 16px;
        top: 8px;
    }
}