@import '../../scss/style.scss';

.footer {
    padding-top: 45px;
}

.footer__container {
    padding: 45px 45px 60px;
    margin-bottom: 28px;
    background-color: $colorFooterBackground;
    border-radius: 50px;

    & .footer__top {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-bottom: 60px;

        @media (max-width: 768px) {
            flex-direction: column;
        }
    }

    & .footer__logo {
        margin-right: auto;
    }

    & .footer__mail {
        display: flex;
        @include btn-reset;
        align-items: center;
        margin-right: 23px;
    }


    & .footer__nav {
        display: flex;
        // justify-content: space-between;
        gap: 30px 12.5%;
        flex-wrap: wrap;

        // @media (max-width: 1200px) {
        //     gap: 15%;
        // }
        @media (max-width: 684px) {
            gap: 30px 5%;
        }
    }

    .footer__wrap {
        width: 25%;

        @media (max-width: 684px) {
            width: 30%;
        }

        @media (max-width: 650px) {
            width: 100%;
        }
    }

    // .footer__wrap:not(:last-child) {
    //     margin-bottom: 15px;
    // }

    & .footer__title {
        margin-bottom: 20px;
        @include title;
        // font-size: 16px;
        line-height: 27px;
        color: $colorMain;
    }

    & .footer__list {
        @include textSans;
        font-size: 16px;
        line-height: 18px;
    }

    & .footer__item:not(:last-child) {
        margin-bottom: 10px;
    }
}

.footer__mail .footer__mail-arrow {
    transition: fill .3s ease-in-out;
}

.footer__mail:focus-visible .footer__mail-arrow,
.footer__mail:hover .footer__mail-arrow,
.footer__mail:active .footer__mail-arrow {
    fill: $colorRed;
}

.personal-btn {
    @include btn-reset;
    padding: 8px 47px;
    background-color: $colorMain;
    border-radius: 25px;
    font-size: 16px;
    line-height: 20px;
    box-shadow: 0px 4px 10px rgba(12, 7, 50, 0.25);
    color: $colorWhite;
    @include grey-linear;
}

.footer__bottom {
    display: flex;
    align-items: center;
    padding-top: 13px;
    padding-bottom: 13px;
    justify-content: space-between;

    & .social,
    .social__list {
        display: flex;
        align-items: center;
        align-self: center;
    }

    & .social__title {
        @include textSans;
        margin-right: 15px;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
    }

    & .social__item:not(:last-child) {
        margin-right: 23px;
    }
}

.footer__link {
    transition: color .2s ease-in-out;
    outline: 0;

    &:focus-visible,
    &:hover,
    &:active {
        color: $colorRed;
    }
}

.footer__logo {
    outline: 0;
    padding: 5px;
    padding-bottom: 0;
    transition: box-shadow .3s ease-in-out;

    & path {
        transition: fill .2s ease-in-out;
    }

    & .color-black {
        transition: fill .2s ease-in-out;
    }

    // &:focus-visible,
    // &:hover,
    // &:active {
    //     // transform: scale(1.1);
    //     border-radius: 25px;
    //     box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.7);
    // }

    &:focus-visible path,
    &:hover path,
    &:active path {
        fill: $colorRed;
    }

    &:focus-visible .color-black,
    &:hover .color-black,
    &:active .color-black {
        fill: $colorBlack;
    }
}

.social__link {
    font-size: 0;
}

.social__link svg {
    width: 80px;
    height: fit-content;
    transition: transform .3s ease-in-out;
}

.social__link:focus-visible svg,
.social__link:hover svg,
.social__link:active svg {
    transform: scale(1.1);
}


@media (max-width: 768px) {
    .footer__container .footer__logo {
        margin: 0;
        margin-bottom: 40px;
    }

    .footer__container .footer__mail {
        margin-bottom: 20px;
        margin-right: 0;
    }

}

@media (max-width: 650px) {
    .footer__container .footer__nav {
        align-items: center;
        text-align: center;
        flex-direction: column;
        gap: 0;
    }

    .footer__container .footer__wrap:not(:last-of-type) {
        margin-bottom: 64px;
    }

}

@media (max-width: 576px) {

    .footer__container {
        padding: 45px 0;
    }

    .personal-btn {
        padding: 8px 40px;
    }

    .footer__bottom {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .footer__bottom .social {
        flex-direction: column;
        margin-bottom: 20px;
    }

    .footer__bottom .social__title {
        text-align: center;
        margin-right: 0;
        margin-bottom: 20px;
    }

    // .social__link svg {
    //     width: 30px;
    //     height: 30px;
    // }
}